import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Form } from "react-bootstrap"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Slider from "react-slick";
import ScrollAnimation from 'react-animate-on-scroll';
import NoImage from "../../../images/cubitt-no-image.png"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import PlayVideo from '../../Play/PlayVideo'
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

import "./Banner.scss"
const settings = {
  dots: false,
  speed: 800,
  infinite: false,
  arrows: true,
  mobileFirst: true,
  centerMode: false,
  responsive: [
    {
      breakpoint: 3020,
      settings: {
        slidesToShow: 2.2,

      }
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 2.1
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1
      }
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1
      }
    }
  ]
};
const Banner = (props) => {
  const [isPlay, setPlay] = useState(false);

  // Property images lightbox
  const [photoIndex, setPhotoIndex] = useState(0);
  const [photoFloorIndex, setPhotoFloorIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isFloorOpen, setIsFloorOpen] = useState(false);

  const openPropertyImage = (e, ind) => {
    e.preventDefault();
    setPhotoIndex(ind);
    setIsOpen(true);
  }
  const openFloorImage = (e, ind) => {
    e.preventDefault();
    setPhotoFloorIndex(ind);
    setIsFloorOpen(true);
  }
  // Property images lightbox


  const propertyImages = props.propertyData.images;
  const floorImages = props.propertyData.floorplan;

  // ggfx
  let processedImages = JSON.stringify({});
  if (props.propertyData?.imagetransforms?.images_Transforms) {
    processedImages = props.propertyData.imagetransforms.images_Transforms;
  }
  // ggfx
 

  // Property details images lightbox
  var propertyLightImages = propertyImages && propertyImages.map(img => img.srcUrl);
  var floorLightImages = floorImages && floorImages.map(img => img.srcUrl);
  // Property details images lightbox

  return (
    <React.Fragment>
      {propertyImages.length > 0 &&
        <section className="detail-banner">
          {props.propertyData?.developer_logo && !isPlay &&
            <div className="developer-logo d-none d-lg-flex">
              {props.propertyData?.developer_logo.map((logo, k) => {
                return (<div className="img">
                  <LazyLoadImage
                    wrapperProps={{ style: { display: "block" } }}
                    alt={'property Image'}
                    effect="blur"
                    src={logo.srcUrl} />
                </div>)

              })}
            </div>

          }

          <div className="banner-img">
            {isPlay ?
              <>
                <button type="button" className="btn-play d-none d-lg-flex inline-play" onClick={(e) => { setPlay(false) }}>
                  <i className="icon-close"></i>
                </button>
                <PlayVideo url={props.propertyData.banner_video} />
              </>

              : <picture>
                 {/*<LazyLoadImage
                  wrapperProps={{ style: { display: "block" } }}
                  alt={'property Image'}
                  effect="blur"
                  src={propertyImages[0].srcUrl} />*/}
                <ImageTransform
                  imagesources={propertyImages[0].url}
                  renderer="srcSet"
                  imagename="new-developments.images.details"
                  attr={{ alt: `${props.propertyData.development_title} - Cubitt & West`, class: '' }}
                  imagetransformresult={processedImages}
                  id={props.propertyData.id}
                  ggfxName="FEED"
                /> 

              </picture>
            }


          </div>
          {!isPlay &&
            <div className="banner-info">
              <div className="link-wrapper">
                <a href="javascript:void(0)" onClick={(e) => openPropertyImage(e, 0)}>
                  <i className="zoom-out">
                  </i>
                  {propertyImages.length} Photos
                </a>
                {floorImages && floorImages.length > 0 &&
                  <a href="javascript:void(0)" onClick={(e) => openFloorImage(e, 0)}>
                    <i className="floor-plan">
                    </i>
                    <span className="d-none d-md-block">Floorplan</span>
                  </a>
                }
                {/* <Link href="#">
                  <i className="icon-video">
                  </i>
                  <span className="d-none d-md-block">Video Walk Round </span>
                </Link>  */}
                {
                  props.propertyData.brochure && props.propertyData.brochure[0]?.srcUrl &&
                  <a href={props.propertyData.brochure[0]?.srcUrl} target="_blank">
                    <i className="icon-book">
                    </i>
                    <span className="d-none d-md-block">Brochure</span>
                  </a>
                }

              </div>

            </div>
          }
          {
            props.propertyData?.banner_video && !isPlay &&
            <button type="button" className="btn-play d-none d-lg-flex" onClick={(e) => { setPlay(true) }}>
              <i className="icon-play-blue"></i>
            </button>
          }


        </section>
      }
      {/* Property image lightbox */}
      {isOpen && (
        <Lightbox
          mainSrc={propertyLightImages[photoIndex]}
          imageTitle={`${(photoIndex + 1)}/${propertyLightImages.length}`}
          nextSrc={propertyLightImages[(photoIndex + 1) % propertyLightImages.length]}
          prevSrc={propertyLightImages[(photoIndex + propertyLightImages.length - 1) % propertyLightImages.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + propertyLightImages.length - 1) % propertyLightImages.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
          }
        />
      )}

      {isFloorOpen && (
        <Lightbox
          mainSrc={floorLightImages[photoFloorIndex]}
          nextSrc={floorLightImages[(photoFloorIndex + 1) % floorLightImages.length]}
          prevSrc={floorLightImages[(photoFloorIndex + floorLightImages.length - 1) % floorLightImages.length]}
          onCloseRequest={() => setIsFloorOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoFloorIndex + floorLightImages.length - 1) % floorLightImages.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoFloorIndex + 1) % floorLightImages.length)
          }
        />
      )}
      {/* Property image lightbox */}

    </React.Fragment>
  )
}
export default Banner
